import React from "react";
import {Link} from "react-router-dom";
import { isMobile } from 'react-device-detect';


const Footer = () => {
    return (
        <div className={'bottom-0'}>
            {!isMobile ?
            <footer className='h-96 bg-[#183729] bottom-0 text-white pl-8 text-lg lg:text-xl pt-[10vh] relative pb-2 w-full'>

                <div className={'flex justify-center'}>
                    <div className='flex justify-start flex-row'>
                        <div>
                            <div className='pt-8 font-bold text-gray-400'>
                                Contact:
                                <ul className='font-normal pt-4 text-white'>
                                    <li>
                                        Email: albinauricdev@gmail.com
                                    </li>
                                    <li className='pt-4'>
                                        Twitter: twitterlink
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='pl-[5vw] pt-8 font-bold  text-gray-400'>
                            Navigation:
                            <ul className='flex justify-start items-baseline flex-row font-normal text-white'>
                                <ul className='pr-8 pt-4'>
                                    <li><Link to="/">Home</Link></li>
                                    <li className='pt-4'><Link to="/features">Features</Link></li>
                                </ul>
                                <ul className='pt-4'>
                                    <li><Link to="/pricing">Pricing</Link></li>
                                    <li className='pt-4'>About</li>
                                </ul>


                            </ul>
                        </div>

                    </div>
                </div>

                <div className='text-lg pt-12'>
                    Copyright © 2023 BypassVPN. All rights reserved
                </div>

            </footer> :
            <footer>
                <div className='text-xs bottom-0 pt-12 bg-[#183729] w-full relative bottom-0 text-white h-full pl-4 pb-2'>
                    Copyright © 2023 BypassVPN. All rights reserved
                </div>
            </footer>
            }
        </div>
    );
}
export default Footer;
