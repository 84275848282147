import {motion} from "framer-motion";
import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

const PricingComponent = () => {
    let navigate = useNavigate();

    React.useEffect(() => {
        axios.get(`${baseURL}/api`).then((response) => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });
    }, []);

    function getPayment() {
        axios
            .post(`${baseURL}/create-checkout-session`, {
            })
            .then((response) => {
                console.log(response.data);
            });
    }


    return(
        <section className='flex items-center lg:items-end justify-center flex-col lg:flex-row xl:pt-36 w-full pt-14 max-w-[90%]'>
            <section className='flex items-center justify-center h-auto'>
                <div className='flex items-center justify-start flex-col bg-white w-full md:w-[60vw] lg:w-[40vw] h-auto md:rounded-xl text-center md:shadow-2xl z-20 text-black rounded-lg'>
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1}}
                        transition={{ duration: 0.5 }}
                        className='border-b-2 w-full flex items-center justify-start flex-col pb-2 md:pb-4 pt-[3%] md:pt-[5%]'
                    >
                        <div className='text-center text-2xl md:text-4xl font-semibold'>
                            Yearly Plan
                        </div>
                        <div className='text-center pt-[1%] md:pt-[2%] flex items-center justify-start flex-row'>
                            <div className='text-4xl md:text-6xl font-bold'>£3.99</div>
                            <div className='text-2xl md:text-4xl'>/month</div>
                        </div>
                    </motion.div>

                    <div className={'text-xl md:text-2xl md:text-2xl font-semibold inline flex justify-center items-center flex-col'}>
                        <div className='pt-[5%] md:pt-[10%] w-[90%] md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Hide your traffic from everyone
                            </motion.div>
                        </div>
                        <div className='pt-8 w-[90%]3 md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Bypass web filters
                            </motion.div>
                        </div>
                        <div className='pt-8 w-[90%] md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Supports Windows, MacOS, Linux, iOS and Android
                            </motion.div>
                        </div>
                        <div className='pt-8 w-[90%] md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Save £12 per year
                            </motion.div>
                        </div>
                        <div className='pt-[5%] md:pt-[10%]'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                <motion.button
                                    className={"text-xl md:text-2xl text-center bg-[#15803d] rounded-lg text-white h-[5vh] w-auto hover:bg-black hover:text-white"}
                                >
                                    <div className={'mr-5 ml-5'} onClick={() => navigate('/signIn', {state : {type: "yearly"}})}>
                                        Start FREE trial
                                    </div>
                                </motion.button>
                            </motion.div>
                            <div className='pt-2 text-sm md:text-lg font-normal pb-4 md:pb-9'>
                                <motion.div
                                    initial={{ opacity: 0}}
                                    animate={{ opacity: 1}}
                                    transition={{ duration: 0.5 }}>
                                    Pay £47.88 now
                                </motion.div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <section className='flex items-center justify-center h-auto border-t-8 border-[#183729] text-black'>
                <div className='flex items-center justify-start flex-col bg-white md:w-[60vw] lg:w-[40vw] h-auto md:rounded-xl text-center md:shadow-2xl z-10 text-black rounded-lg'>
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1}}
                        transition={{ duration: 0.5 }}
                        className='border-b-2 w-full flex items-center justify-start flex-col pb-4 md:pb-9 pt-[3%] md:pt-[5%]'
                    >
                        <div className='text-center text-2xl md:text-4xl font-semibold'>
                            Monthly Plan
                        </div>
                        <div className='text-center pt-[1%] md:pt-[2%] flex items-center justify-start flex-row'>
                            <div className='text-4xl md:text-6xl font-bold'>£4.99</div>
                            <div className='text-2xl md:text-4xl'>/month</div>
                        </div>
                    </motion.div>

                    <div className={'text-xl md:text-2xl md:text-2xl font-semibold inline flex justify-center items-center flex-col'}>
                        <div className='pt-[5%] md:pt-[7%] w-[90%] md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Hide your traffic from everyone
                            </motion.div>
                        </div>
                        <div className='pt-8 w-[90%]3 md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Bypass web filters
                            </motion.div>
                        </div>
                        <div className='pt-8 w-[90%] md:w-full'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                ✅ Supports Windows, MacOS, Linux, iOS and Android
                            </motion.div>
                        </div>
                        <div className='pt-[5%] md:pt-[10%] pb-10'>
                            <motion.div
                                initial={{ opacity: 0}}
                                animate={{ opacity: 1}}
                                transition={{ duration: 0.5 }}
                            >
                                <motion.button className={"text-xl md:text-2xl text-center bg-[#15803d] rounded-lg text-white h-[5vh] w-auto hover:bg-black hover:text-white"}>
                                    <div className={'mr-5 ml-5'} onClick={() => navigate('/signIn', {state : {type: "monthly"}})}>
                                        Start FREE trial
                                    </div>
                                </motion.button>
                                <div className='pt-2 text-sm md:text-lg font-normal md:pb-5'>
                                    <motion.div
                                        initial={{ opacity: 0}}
                                        animate={{ opacity: 1}}
                                        transition={{ duration: 0.5 }}>
                                    </motion.div>
                                </div>
                            </motion.div>

                        </div>
                    </div>
                </div>
            </section>

        </section>
    );
}

export default PricingComponent;