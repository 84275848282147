import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion"

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendSignInLinkToEmail, onAuthStateChanged, signOut  } from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {useLocation, useNavigate, Link} from "react-router-dom";

import {app} from "./firebase-config";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const SignIn = () => {
    console.log(`${baseURL} BASE_URL`);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState('');
    const [inv, setInv] = useState('');

    const [signInMode, setSignInMode] = useState(true);

    const [forgotPassword, setForgotPassword] = useState(false);

    const [formErrorState, setFormErrorState] = useState(false);
    const [errorType, setErrorType] = useState('');

    const location = useLocation();
    const {type} = location.state;


    let navigate = useNavigate();

    useEffect(() => {
        if(type !== "none"){
            setSignInMode(false);
        }

        if (localStorage.getItem("SignedIn") != null) {
            navigate('/user', {state: {id: localStorage.getItem("SignedIn")}})
        }
    }, []);


    const onSubmit = async (e) => {
        const auth = getAuth(app);

        e.preventDefault()

        if(forgotPassword){
            await sendSignInLinkToEmail(auth, email)
                .then(() => {
                    
                    // window.localStorage.setItem('emailForSignIn', email);
                    // navigate('/user', {state: {id: JSON.stringify(user.uid)}})
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setFormErrorState(true)
                    setErrorType(`${error.message}`)
                });
        }
        else if (signInMode){
            await signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    localStorage.setItem('SignedIn', user.uid);
                    navigate('/user', {state: {id: JSON.stringify(user.uid)}})
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                    setFormErrorState(true)
                    switch (error.code) {
                        case 'auth/wrong-password':
                            // window.alert(`Email address ${email} already in use.`);
                            setErrorType(`Invalid email or password. `)
                            break;
                        case 'auth/user-not-found':
                            // window.alert(`Email address ${email} is invalid.`);
                            setErrorType(`User not found.`)
                            break;
                        case 'auth/invalid-email':
                            // window.alert(`Email address ${email} is invalid.`);
                            setErrorType(`Email address is invalid.`)
                            break;
                        default:
                            // console.log(error.message);
                            // window.alert(`${error.message}`);
                            setErrorType(`${error.message}`)
                            break;
                    }
                });
        }
        else {
            await createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log(user);
                    navigate('/user', {state: {id: JSON.stringify(user.uid)}})
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                    setFormErrorState(true)
                    switch (error.code) {
                        case 'auth/email-already-in-use':
                            // window.alert(`Email address ${email} already in use.`);
                            setErrorType(`Email address already in use.`)
                            break;
                        case 'auth/invalid-email':
                            // window.alert(`Email address ${email} is invalid.`);
                            setErrorType(`Email address is invalid.`)
                            break;
                        case 'auth/operation-not-allowed':
                            // window.alert(`Error during sign up.`);
                            setErrorType(`Error during sign up.`)
                            break;
                        case 'auth/weak-password':
                            // window.alert(`Password is not strong enough. Add additional characters including special characters and numbers.`);
                            setErrorType(`Password is not strong enough. Add additional characters including special characters and numbers.`)
                            break;
                        default:
                            // console.log(error.message);
                            // window.alert(`${error.message}`);
                            setErrorType(`${error.message}`)
                            break;
                    }
                });
        }

    }

    // function emailSignIn(){
    //
    // }
    let onEmailChange = e => {
        setEmail(e.target.value);
    };
    let onPasswordChange = e => {
        setPassword(e.target.value);
    };

    let onInvitationChange = e => {
        setInv(e.target.value);
    };

    useEffect(() => {
        return () => {
            setFormErrorState(false)
        };
    }, [signInMode, forgotPassword]);

    function shouldSendToPricing(){
        navigate('/pricing');
    }

    async function GoToPayments(){
        let emailCheck = true;
        await axios
            .post(`${baseURL}/validateEmail`, {
                email: email
            })
            .then((response) => {
                console.log("here", inv, response.data)
                if(response.data !== 'passed'){
                    console.log("also here", response.data)
                }
                if(inv === '' && response.data === 'passed'){
                    navigate("/payment", {state: {email: email, pass: password, type: type, inv:inv}});
                }
                else if(response.data !== 'passed'){
                    emailCheck = false;
                    setFormErrorState(true);
                    setErrorType(response.data);
                }
                else{
                    console.log('Using inv code')
                    axios
                        .post(`${baseURL}/invReg`, {
                            email: email,
                            inv: inv,
                            password: password
                        })
                        .then((response) => {
                            if (response.data === 'passed') {
                                console.log("YESSSS")
                                navigate("/success");
                            } else {
                                setFormErrorState(true);
                                setErrorType(response.data);
                            }
                        })
                        .catch(function (error) {
                            console.log(':Error', error.message);
                            setFormErrorState(true)
                            setErrorType(`Something went wrong`)
                        });
                }
            })
            .catch(function (error) {

                console.log(':Error', error.message);
                setFormErrorState(true)
                setErrorType(`Something went wrong`)

            });


    }


    return (
        <div>
            <div className="bg-[#183729]">
                <motion.div
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    transition={{ duration: 0.5 }}
                    className={'w-[100%] flex justify-center items-center flex-col h-[100vh] text-[#1B4045]'}
                    key={signInMode}
                >
                    <div className="rounded-lg p-14 w-[90%] max-w-lg shadow-lg z-10 border-2 bg-white">
                        {signInMode ?
                            <div>
                                <div className={'text-2xl font-bold text-center pb-4'}>Sign In</div>
                                <div className={'flex justify-center items-center flex-row'}>
                                    <div className={'text-sm text-center pb-4'}>Don't have an account? </div>
                                    <div className={'text-sm text-center pb-4 cursor-pointer underline pl-2 font-bold'} onClick={() => shouldSendToPricing()}> Sign up </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={'text-2xl font-bold text-center pb-4'}>Sign Up</div>
                                <div className={'flex justify-center items-center flex-row'}>
                                    <div className={'text-sm text-center pb-4'}>Already have an account? </div>
                                    <div className={'text-sm text-center pb-4 cursor-pointer underline pl-2 font-bold'} onClick={() => setSignInMode(true)}> Sign in </div>
                                </div>
                            </div>}

                        <div className="form-group mt-3">
                            <label className={`text-sm font-semibold`}>Email address</label><br/>
                            <input
                                type="email"
                                className={`w-full p-2 rounded-lg mt-1 text-sm border-2 ${formErrorState && 'border-red-600'}`}
                                placeholder="Enter email"
                                value={email}
                                onChange={onEmailChange}
                            />
                        </div>
                        {!forgotPassword &&
                            <div className="form-group mt-3">
                                <label className={'text-sm font-semibold'}>Password</label><br/>
                                <input
                                    type="password"
                                    className={`w-full p-2 rounded-lg mt-1 text-sm border-2 ${formErrorState && 'border-red-600'}`}
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={onPasswordChange}
                                />

                            </div>


                        }
                        {!signInMode &&
                        <div className="form-group mt-3">
                            <label className={'text-sm font-semibold'}>Invitation code</label><br/>
                            <input
                                type="inv"
                                className={`w-full p-2 rounded-lg mt-1 text-sm border-2 ${formErrorState && 'border-red-600'}`}
                                placeholder="Enter the invitation code"
                                value={inv}
                                onChange={onInvitationChange}
                            />

                        </div>}

                        <div className="d-grid gap-2 mt-3">
                            {signInMode ?
                            <button
                                type="submit"
                                className={'bg-[#1B4045] w-full h-auto pt-2 pb-2 rounded-xl mt-4 text-white font-semibold'}
                                onClick={onSubmit}
                            >
                                Submit
                            </button>
                                :
                            <button className={'bg-[#1B4045] w-full h-auto pt-2 pb-2 rounded-xl mt-4 text-white font-semibold'} onClick={() => {GoToPayments()}}>
                                Start free trial
                            </button>
                            }
                        </div>
                        {formErrorState && <div className={'text-sm text-center pb-4 text-red-600'}>{errorType}</div>}

                        <p className="text-right mt-2">
                            <a href="#" onClick={() => setForgotPassword(true)}>Forgot password?</a>
                        </p>
                    </div>
                </motion.div>

            </div>
        </div>
    );
}

export default SignIn;