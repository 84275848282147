import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Pricing from './pricing';
import Features from "./features";
import Success from "./success";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import SignIn from "./signIn";
import User from "./user";
import Payment from "./payment";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="features" element={<Features />} />
            <Route path="success" element={<Success />} />
            <Route path="signIn" element={<SignIn />} />
            <Route path="user" element={<User/>} />
            <Route path="payment" element={<Payment/>}/>
        </Routes>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
