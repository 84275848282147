import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDGxisOOAFgRzuTvuvpmMR60yAXCinZBYw",
    authDomain: "bypass-vpn-1146d.firebaseapp.com",
    projectId: "bypass-vpn-1146d",
    storageBucket: "bypass-vpn-1146d.appspot.com",
    messagingSenderId: "1051068488930",
    appId: "1:1051068488930:web:b5be68ecf4ac5d332c92bb",
    measurementId: "G-YMXDTH2YTE"
};

export const app = initializeApp(firebaseConfig);