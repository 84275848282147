import React, {useState, useEffect}  from "react";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import Footer from "./footer";
import Nav from "./navigation";

import PricingComponent from "./pricingComponent";


const Features = () => {
    const [backgroundIndex, setBackgroundIndex] = useState(0)

    useEffect(() => {
        setBackgroundIndex(Math.floor(Math.random() * 5));
    }, []);

    const backgrounds = ['bg-blob', 'bg-blob2', 'bg-blob3', 'bg-blob4', 'bg-blob5']

    return (
        <div className={`h-full ${backgrounds[backgroundIndex]} bg-cover bg-no-repeat`}>
            <Nav/>
            <section className='flex items-center justify-around h-auto bg-no-repeat text-white flex-row text-lg sm:text-lg md:text-xl lg:text-3xl pt-24 md:pt-44 flex-col md:flex-row'>
                <div className='flex items-center justify-center flex-col max-w-[90%]'>
                    <div>
                        <div className='font-bold text-7xl lg:text-9xl text-center'><AllInclusiveIcon className={'scale-[1.5] md:scale-[2.5] lg:scale-[4]'}/></div>
                        <div className='pt-5 md:pt-14 font-semibold md:w-[35vw] text-center'>With BypassVPN, you get unlimited bandwidth on all plans for all of your devices. Stream, browse, and download as much as you want without any restrictions.</div>
                    </div>
                    <div className='pt-10 md:pt-[30vh]'>
                        <div className='font-bold text-7xl lg:text-9xl text-center'><FilterAltOffIcon className={'scale-[1.5] md:scale-[2.5] lg:scale-[4]'}/></div>
                        <div className='pt-5 md:pt-14 font-semibold md:w-[35vw] text-center'>BypassVPN helps you bypass firewalls and web filters so that you can access the content you want, no matter where you are in the world.</div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col max-w-[90%]'>
                    <div>
                        <div className='pt-10 md:pt-0 font-bold text-7xl lg:text-9xl text-center'><DesktopMacIcon className={'scale-[1.5] md:scale-[2.5] lg:scale-[4]'}/></div>
                        <div className='pt-5 md:pt-14 font-semibold md:w-[35vw] text-center'>Our VPN supports multiple platforms, including Windows, Mac, iOS, Android, and Linux so you can use it on any device you own.</div>
                    </div>
                    <div className='pt-10 md:pt-[30vh]'>
                        <div className='font-bold text-7xl lg:text-9xl text-center'><InsertPageBreakIcon className={'scale-[1.5] md:scale-[2.5] lg:scale-[4]'}/></div>
                        <div className='pt-5 md:pt-14 font-semibold md:w-[35vw] text-center'>We have a strict no-logging policy, which means that your data is completely private and not stored on our servers.</div>
                    </div>
                </div>
            </section>
            <section className='flex items-center flex-row justify-center h-auto md:justify-end xl:justify-center pt-44 bg-no-repeat text-white flex-row text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center flex-col md:font-semibold mb-20 lg:mb-44'>
                <div className='xl:w-[75vw] max-w-[90%]'>
                    BypassVPN is designed to help you to bypass any firewalls or web filters. We use Outline VPN, an open-source project that uses shadowsocks to help encrypt your data, making it useless for anyone who wants to read it.
                </div>
                <PricingComponent/>
            </section>
            <Footer/>
        </div>
    );
}
export default Features;