//create default react component
import React, {useEffect, useRef, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import { useLocation } from "react-router-dom";
import footer from "./footer";
import { getAuth, signOut } from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import axios from "axios";

// import { OutlineVPN } from 'outlinevpn-api'

const baseURL = process.env.REACT_APP_BASE_URL; //https://bypass-vpn-server.vercel.app

function Nav(props){
    return(
        <div className={'pt-10 flex flex-row w-full justify-between'}>
            <div className={'text-3xl'}>{props.section}</div>
            <div className={'text-xl flex flex-row'}>
                <div className={'mr-10'}>
                    <div className={'hover:bg-gray-200 p-2 rounded-md cursor-pointer text-lg'}>
                        Help Center
                    </div>
                </div>
                <div>
                    <div className={'hover:bg-gray-200 p-2 rounded-md cursor-pointer text-lg'}>
                        getemailfrombackend@gmail.com
                    </div>
                </div>
            </div>
        </div>
    );
}

const Home = (props) => {

    return (
        <div className={'flex flex-col justify-between'}>

            {!props.data && <Nav section={'Home'}/>}
            <div className={'text-2xl xl:text-4xl font-normal pt-10 font-semibold'}>Welcome back 👋</div>

        </div>
    );
}

const CopyToClipboard = props => {
    const [visible, setVisible] = useState(true);
    const delay = 2500;

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, delay);
        return () => clearTimeout(timer)
    }, [delay]);

    return visible ?
        <div className={'w-full md:w-3/4 text-white bg-green-700 p-3 rounded-lg text-center mt-5 drop-shadow-lg'}>
            Copied to clipboard
        </div>
            : null;
};

const NewDevice = (props) => {
    const [notice, setNotice] = useState(<div></div>);
    const [childKey, setChildKey] = useState(1);

    const [key, setKey] = useState('ss://....................................');

    function getKey(userId) {
        //USER ACCOUNT ID IS BE PASSED

        axios
            .post(`${baseURL}/getUserKey`, {
                userId: userId
            })
            .then((response) => {
                setKey(response.data)
            })
            .catch(function (error) {
                console.log(':Error', error.message);
            });
    }

    useEffect(() => {
        let userId = props.id.replace('"','');
        userId = userId.replace('"','');
        getKey(userId);
    }, []);



    function ClickCopyToClipBoard(){
        navigator.clipboard.writeText(key);

        setChildKey(childKey+1)
        setNotice(<CopyToClipboard key={childKey}/>)
    }

    return (
        <div className={'flex flex-col justify-between'}>

            {!props.data && <Nav section={'Connect a new device'}/>}
            <div className={'text-2xl md:text-3xl xl:text-4xl font-normal pt-10 md:pt-16 font-semibold text-center md:text-left'}>Follow these steps to set up your new device</div>
            <div className={'flex justify-start flex-col pt-14 md:text-2xl'}>
                <div>
                    <div className={'text-3xl font-bold w-full text-center md:text-left md:text-3xl pb-2 mb-5 text-[#017a40] border-b-4'}>1. Downloading</div>
                    <div>
                        Download Outline Client for your operating system
                        <a href="https://getoutline.org/get-started/#step-3" target="_blank" className={'pl-1 text-blue-700 underline hover:text-blue-900'}>
                            by following this link.
                        </a>
                    </div>
                </div>
                <div className={'pt-14'}>
                    <div className={'text-3xl font-bold w-full text-center md:text-left md:text-3xl pb-2 mb-5 text-[#017a40] border-b-4'}>2. Setting up</div>
                    <div>
                        If you already had it installed just press the plus icon to add another server. If it is your first server, just paste the key and you are ready to use it.
                    </div>
                </div>
                <div className={'pt-14'}>
                    <div className={'text-3xl font-bold w-full text-center pb-9 text-[#017a40]'}>Your account key: </div>
                    <div className={'flex justify-center'}>
                        <div onClick={() => {ClickCopyToClipBoard()}} className={'w-full md:w-3/4 bg-gray-400 p-2 rounded-lg flex justify-between flex-row hover:bg-gray-100 hover:cursor-pointer'}>
                            <div className={'whitespace-nowrap overflow-hidden mr-5'}>{key}</div>
                            <div><ContentCopyIcon/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'top-16 flex justify-center'}>
                {notice}
            </div>
        </div>
    );
}




const User = () => {
    const ref = useRef()

    const location = useLocation();
    const { id } = location.state;
    const [currentSection, setCurrentSection] = useState(0);
    const [mobileMenu, setMobileMenu] = useState(false);

    const isSmallScreen = useMediaQuery({ query: '(max-width: 1280px)' })


    const sections = [<Home data={isSmallScreen}/>, <NewDevice data={isSmallScreen} id={id}/>];
    let navigate = useNavigate();

    useEffect(() => {
        const checkIfClickedOutside = e => {

            if (mobileMenu && ref.current && !ref.current.contains(e.target)) {

                setMobileMenu(false)

            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)


        return () => {

            // Cleanup the event listener

            document.removeEventListener("mousedown", checkIfClickedOutside)

        }
    }, [mobileMenu]);


    const SignOut = () =>{
        localStorage.removeItem('SignedIn');
        navigate('/');
    }

    const ClickNav = (index) => {
        setCurrentSection(index);
        setMobileMenu(false);
    }

    return (
        <div>
            {!isSmallScreen ?
                <nav className={'h-[100vh] w-[10%] bg-[#183729] text-white font-bold fixed'}>
                    <img src={'/smallLogoV3.png'} className='w-full pt-10 pb-10 pl-4 pr-4 h-auto cursor-pointer'
                        alt={'logo'}/>
                            <div className={'flex justify-start items-start flex-col'}>
                        <div className={'mb-2 mt-6 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                            onClick={() => setCurrentSection(0)}>Home
                        </div>
                        <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                            onClick={() => setCurrentSection(1)}>Connect a new device
                        </div>
                        <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                            onClick={() => setCurrentSection(2)}>My usage
                        </div>
                        <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                            onClick={() => setCurrentSection(3)}>Subscription
                        </div>
                        <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                            onClick={() => setCurrentSection(4)}>Account
                        </div>
                        <div className={'hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'} onClick={SignOut}>Log
                            out
                        </div>
                    </div>
                </nav>
                :
                <div>
                    <div className={'h-[60px] absolute w-full shadow-md'}>
                        <MenuIcon ref={ref} className='left-5 top-5 absolute h-[20px] w-auto cursor-pointer' onClick={() => setMobileMenu(!mobileMenu)} sx={{ scale: '1.4'}}/>
                        <img src={'/fullLogo.png'} className='right-0 mr-[14px] mt-[14px] absolute h-[35px] w-auto cursor-pointer'
                            alt={'logo'} onClick={() => navigate('/')}/>
                    </div>
                    {mobileMenu &&
                        <nav className={'h-[100vh] w-[75%] bg-[#183729] text-white font-bold fixed'} ref={ref} >
                            <CloseIcon ref={ref} className='left-5 top-5 fixed h-[20px] w-auto cursor-pointer' onClick={() => setMobileMenu(!mobileMenu)} sx={{ color: "white", scale: '1.4'}}/>
                            <div className={'flex justify-start items-start flex-col pt-12'}>
                                <div className={'mb-2 mt-6 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                                    onClick={() => ClickNav(0)}>Home
                                </div>
                                <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                                    onClick={() => ClickNav(1)}>Connect a new device
                                </div>
                                <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                                    onClick={() => ClickNav(2)}>My usage
                                </div>
                                <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                                    onClick={() => ClickNav(3)}>Subscription
                                </div>
                                <div className={'mb-2 hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'}
                                    onClick={() => ClickNav(4)}>Account
                                </div>
                                <div className={'hover:bg-[#1B4045] w-full pb-4 pt-4 pl-4 cursor-pointer'} onClick={SignOut}>
                                    Log out
                                </div>
                            </div>
                        </nav>}
                </div>

            }

            <section className={'ml-12 mr-12 pt-12 xl:ml-[25%] xl:mr-[15%] z-50'}>
                {sections[currentSection]}
            </section>
        </div>
    );
}

export default User;