import React, {useState, useEffect}  from "react";
import Nav from "./navigation";
import PricingComponent from "./pricingComponent";
import Footer from "./footer";


const Pricing = () => {



    return (
        <div className='h-full bg-[#183729] min-h-screen'>
            <Nav/>

            <section className={'flex justify-center items-center flex-col'}>
                <div className={'text-4xl lg:text-5xl xl:text-7xl text-white pt-32 lg:pt-36 font-bold'}>7 days Free trial</div>
                <div className={'text-2xl lg:text-xl xl:text-3xl text-white pt-10 font-semibold'}>Buy more pay less</div>
                <PricingComponent/>
            </section>
            <Footer/>
        </div>
    );
}
export default Pricing;