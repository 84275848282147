import React from 'react';
import Nav from "./navigation";
import Footer from "./footer";
import {motion} from "framer-motion";
import { useNavigate } from "react-router-dom";


const Success = () => {
    const navigate = useNavigate();

    function returnHome() {
        navigate("/");
    }
    return (
        <div>
            <Nav/>
            <section className={'flex justify-center items-center h-[100vh] w-full flex-col text-center'}>
                <div className={'text-4xl font-bold'}>Your purchase was successful.</div>
                <div className={'pt-14 text-2xl pb-14'}>You will get an email from us with installation instructions shortly.</div>
                <motion.button
                    className={"text-xl md:text-2xl text-center bg-[#15803d] rounded-lg text-white h-[5vh] w-auto hover:bg-black hover:text-white pr-14 pl-14"}
                    onClick={returnHome}
                >
                    Return
                </motion.button>
            </section>
            <Footer/>
        </div>
    );
};

export default Success;
