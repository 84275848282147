import {useLayoutEffect} from "react";
import React, {useState, useEffect} from "react";
// import axios from 'axios'
import { motion } from "framer-motion"
import axios from "axios";
// import {publicIp, publicIpv4, publicIpv6} from "public-ip";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import {Link} from "react-router-dom";
import Footer from "./footer"
import { isMobile } from 'react-device-detect';
// import {useNavigate} from 'react-router-dom';

import Nav from "./navigation";
import PricingComponent from "./pricingComponent";

import { getAuth } from "firebase/auth";



// $.getJSON('http://ip.jsontest.com/', function(data) {
//     console.log(JSON.stringify(data, null, 2));
// });
//

function ShowGeneralAbout() {
  return (
      <div>
          <motion.div
              initial={{ opacity: 0}}
              animate={{ opacity: 1}}
              transition={{ duration: 0.5 }}
          >
              <section className='h-[90vh] flex justify-center items-center flex-col text-[#1B4045] font-mono'>
                  <img src={'/bypassvpnLogoPowerBy.png'} className='w-auto h-[200px] md:h-[300px] lg:h-[500px] object-cover fixed -z-10'/>
              </section>

          </motion.div>
          <section className='h-auto flex justify-center items-center flex-col text-white leading-snug text-lg md:text-xl lg:text-2xl xl:text-3xl text-left font-mono bg-[#183729]'>
              <div className={'pr-12 pl-12 w-full flex justify-center items-center flex-col'}>
                  <div className='w-auto max-w-[95%] md:w-[90%] lg:w-[65%] w-full pt-24 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold'>
                      What we provide
                  </div>
                  <div className='w-auto md:w-[90%] lg:w-[65%] w-full pt-12'>
                      We understand that common VPN protocols like OpenVPN and Wireguard are easily detected and blocked by firewalls, which is why we exclusively use Shadowsocks protocol.
                  </div>
                  <div className='w-auto md:w-[90%] lg:w-[65%] w-full pt-12'>
                      This powerful encryption technology is highly effective at keeping your online activity anonymous and undetectable, giving you unrestricted access to the internet.
                  </div>
                  <div className='w-auto md:w-[90%] lg:w-[65%] w-full pt-12'>
                      But don't just take our word for it - we offer a seven-day free trial to let you experience the benefits of our service for yourself, risk-free. After that, you can continue using our VPN for just £3.99 per month, which includes unlimited bandwidth to any device you might have.
                  </div>
                  <div className='w-auto md:w-[90%] lg:w-[65%] w-full pt-12 pb-12'>
                      Choose BypassVPN and browse the internet with confidence, knowing your online privacy is in safe hands.
                  </div>
              </div>

              <PricingComponent/>

          </section>
      </div>

  );
}


function Empty() {
    return (
        <div className='h-[100%]'>
            .
        </div>
    );
}

function ShowAbout() {
  return (
      <motion.div
          initial={{ opacity: 0, y:300}}
          animate={{ opacity: 1, y:0}}
          transition={{ duration: 0.5 }}
      >
          <section className='h-[98vh] flex justify-center items-center items-center flex-col text-center'>
              <div className='text-5xl font-bold pb-10'>Bypass VPN</div>
              <div className='text-2xl font-semibold w-1/2'>We are a VPN hosting company that aims to help people being abused by various web filters and firewalls. Our primary purpose is to be reliable.
                  While the firewalls are blocking many VPNs, we are invisible to them.
              </div>
          </section>
      </motion.div>
  );
}



function App() {
    const [ip, setIP] = useState('');

    const backgroundColors = ['#183729', '#183729', '#ffff']; //dots, lines, background


    useEffect(() => {
          // axios.get("/getIP").then((response) => {
          //     setIP(response.data);
          // });
        // console.log(h)
    }, []);


    const particlesInit = async (main) => {
        console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

  return (
      <div className='h-full'>
          <Nav/>
          <div className='h-full'>
              <ShowGeneralAbout/>
          </div>
          <Footer/>

      </div>
  );
}

export default App;
