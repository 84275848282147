import React, {useState, useEffect}  from "react";
import Nav from "./navigation";
import PricingComponent from "./pricingComponent";
import Footer from "./footer";
import {useLocation} from "react-router-dom";
import axios from "axios";


const baseURL = process.env.REACT_APP_BASE_URL;



const Payment = () => {
    const location = useLocation();
    // const {st} = location.state;

    const email = location.state.email;
    const pass = location.state.pass;
    const type = location.state.type;


    async function sendToBuy() {
        await axios
            .post(`${baseURL}/create-checkout-session`, {
                email: email,
                password: pass,
                type: type
            })
            .then((response) => {
                window.location.href = response.data;
            });

    }

    return (
        <div className={'flex justify-center items-center h-[100vh] w-full flex-col'}>
            <button className={'flex flex-row bg-purple-400 w-[300px] h-[100px] mb-20 rounded-lg text-xl text-center justify-center items-center border-4 border-purple-500 hover:bg-purple-500 active:bg-purple-600'} onClick={async () => await sendToBuy()}>
                <img src={'/stripe_logo.png'} className='w-[150px] h-auto'/>
            </button>
            <button className={"flex items-center justify-center bg-yellow-300 w-[300px] h-[100px] rounded-lg border-4 border-yellow-400 hover:bg-yellow-400 active:bg-yellow-500"}>
                <img src={'/iomoney_logo.png'} className=' w-[200px] h-auto text-lg rounded-lg text-xl'/>
            </button>
        </div>
    );
}
export default Payment;