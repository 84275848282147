import React, {useState, useRef, useEffect} from "react";
import {Link} from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {motion, AnimatePresence } from "framer-motion";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';



import { getAuth } from "firebase/auth";
import {app} from "./firebase-config";


const MobileNav = (props) => {
    return(
        <div className={'flex justify-center items-start flex-col text-lg -z-50'}>
            <Link to="/" className={'mt-10 pl-5 w-full hover:bg-[#1E4533] active:bg-[#1E4533] text-left'}>Home</Link>
            <Link to="/features" className={'mt-10 pl-5 w-full hover:bg-[#1E4533] active:bg-[#1E4533] text-left'}>Features</Link>
            <Link to="/pricing" className={'mt-10 pl-5 w-full hover:bg-[#1E4533] active:bg-[#1E4533] text-left'}>Pricing</Link>
            <Link to="/" className={'mt-10 pl-5 w-full hover:bg-[#1E4533] active:bg-[#1E4533] text-left'}>Contact</Link>
            {!props.data ?
                <Link to="/signIn" state={{type: "none"}} className={'mt-10 pl-5 w-full hover:bg-[#1E4533] active:bg-[#1E4533] text-left mb-10'}>Sign In</Link>
                :
                <Link to="/signIn" state={{type: "none"}} className={'mt-10 pl-5 w-full hover:bg-[#1E4533] active:bg-[#1E4533] text-left mb-10'}>Account</Link>
            }

        </div>
    );
}


const Nav  = () => {
    const ref = useRef()

    const [open, setOpen] = useState(false)
    const[signedIn, setSignedIn] = useState(false)


    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `/`;
        navigate(path);
    }

    useEffect(() => {
        if (localStorage.getItem("SignedIn") != null) {
            setSignedIn(true)
            console.log('Signed in')
        }
        else{
            console.log('Signed out')
        }

        const checkIfClickedOutside = e => {

            if (open && ref.current && !ref.current.contains(e.target)) {

                setOpen(false)

            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)


        return () => {

            // Cleanup the event listener

            document.removeEventListener("mousedown", checkIfClickedOutside)

        }
    }, [open])

    const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' });

    const OpenUser = () => {
        console.log('clicked');
        if(signedIn){
            console.log('navigating');
            navigate('/user', {state: {id: localStorage.getItem("SignedIn")}});
        }
        else{
            navigate('/signIn', {state : {type: "none"}});
        }
    }

    return (
        <div ref={ref}>
            {isSmallScreen ?
                <nav className="w-full h-[40px] text-white lg:font-semibold bg-[#183729] flex justify-start items-center text-center pt-2 pb-2 font-mono fixed text-sm md:text-lg lg:text-xl z-50">
                    <img src={'/smallLogoV3.png'} className='w-auto h-[75%] object-cover -z-10 pr-14 pl-7 cursor-pointer' onClick={routeChange} alt={'logo'}/>
                    {open ?  <CloseIcon ref={ref} className='right-10 fixed h-[20px] w-auto cursor-pointer' onClick={() => setOpen(!open)}/> :  <MenuIcon className='right-10 fixed h-[20px] w-auto cursor-pointer' onClick={() => setOpen(!open)}/>}


                    {open &&
                        <div ref={ref} className={'w-screen bg-[#183729] h-auto absolute -z-50 top-10'}>
                            <MobileNav data={signedIn}/>
                        </div>}


                        </nav>
                        :
                        <nav className="w-full h-[40px] text-white lg:font-semibold bg-[#183729] flex justify-start items-center text-center pt-2 pb-2 font-mono fixed text-sm md:text-lg lg:text-xl z-50">
                            <img src={'/smallLogoV3.png'} className='w-auto h-[75%] object-cover -z-10 pr-14 pl-7' onClick={routeChange} alt={'logo'}/>
                            <div className='pr-14'><button className='hover:text-gray-600'>
                                <Link to="/">Home</Link>
                            </button></div>
                            <div className='pr-14'><button className='hover:text-gray-600'>
                                <Link to="/features">Features</Link>
                            </button></div>
                            <div className='pr-14'><button className='hover:text-gray-600'>
                                <Link to="/pricing">Pricing</Link>

                            </button></div>
                            <div><button className='hover:text-gray-600'>
                                Contact
                            </button></div>

                            <div className={'right-0 fixed pr-14 md:pr-7 cursor-pointer'}
                            onClick={OpenUser}
                            >
                                <div className={'flex flex-row items-center'}>
                                    {!signedIn ? <div className={'pr-4'}>Login</div> : null}
                                    <PersonIcon/>
                                </div>
                            </div>
                            {/*<button onClick={()=>localStorage.removeItem('SignedIn')}>clear</button>*/}
                        </nav>
            }
        </div>

    );
}
export default Nav;
